import CertCard from "./CertCard.js"

import hr from "../assets/curve-hr.svg"
import pca from "../assets/certs/gcp-pca.png"
import ace from "../assets/certs/gcp-ace.png"
import terraform from "../assets/certs/terraform-associate.png"
import az100 from "../assets/certs/azure-fundamentals.png"
import az104 from "../assets/certs/azure-admin.png"
import newrelic from "../assets/certs/newrelic.png"
import argofundamentals from "../assets/certs/argo-fundamentals.png"
import gitops from "../assets/certs/gitops-scale.png"
import wiz from "../assets/certs/wiz-technical.png"

// import 

export default function Certs(){
    return (
        <div id="certs" className="mt-4 text-white">
            <h1 className="text-2xl font-bold">Certifications</h1>
            <p className="font-light text-gray-400">Here are some of my Certifications</p>

            {/* <div className="flex flex-col md:flex-row flex-wrap mt-4 gap-5"> */}
            <div className="grid grid-cols-1 md:grid-cols-3 justify-center mt-4 gap-5">
                <CertCard name="Google Cloud Certified: Professional Cloud Architect" img={pca} issued="Google" date="Jun 2023" />
                <CertCard name="Google Cloud Certified: Associate Cloud Engineer" img={ace} issued="Google" date="Dec 2021" />
                <CertCard name="Terraform Associate" img={terraform} issued="Hashicorp" date="May 2022" />
                <CertCard name="Microsoft Certified: Azure Administrator Associate" img={az104} issued="Microsoft" date="Jun 2021" />
                <CertCard name="Microsoft Certified: Azure Fundamentals" img={az100} issued="Microsoft" date="Aug 2020" />
                <CertCard name="Newrelic Advance Practitioner" img={newrelic} issued="Newrelic" date="Nov 2022" />
                <CertCard name="Gitops Certified for Argo - Fundamentals Level 1" img={argofundamentals} issued="Codefresh" date="Sep 2023" />
                <CertCard name="Gitops Certified for Argo - at Scale" img={gitops} issued="Codefresh" date="Mar 2024" />
                <CertCard name="Wiz Accredited Partner Technical" img={wiz} issued="Wiz" date="Dec 2023" />
            </div>
            <img src={hr} className="w-full mt-8 md:h-2" alt="hr" />
        </div>
    )
}
