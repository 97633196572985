import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import SkillCard from "./SkillCard.js"

import nodejs from "../assets/skills/nodejs.svg"
import bash from "../assets/skills/bash.svg"
import gcp from "../assets/skills/gcp.svg"
import linux from "../assets/skills/linux.svg"
import python from "../assets/skills/python.svg"
import azure from "../assets/skills/azure.svg"
import datadog from "../assets/skills/datadog.svg"
import grafana from "../assets/skills/grafana.svg"
import kubernetes from "../assets/skills/kubernetes.svg"
import ansible from "../assets/skills/ansible.svg"
import helm from "../assets/skills/helm.svg"
import jenkins from "../assets/skills/jenkins.svg"
import newrelic from "../assets/skills/newrelic.svg"
import docker from "../assets/skills/docker.svg"
import git from "../assets/skills/git.svg"
import prometheus from "../assets/skills/prometheus.svg"
import gitlab from "../assets/skills/gitlab.svg"
import vault from "../assets/skills/vault.svg"
import terraform from "../assets/skills/terraform.svg"

import hr from "../assets/curve-hr.svg"

export default function Skills() {
    const settings = {
        dots: false,
        autoplay: true,
        infinite: true,
        slidesToShow: 2,
        slidesToScroll: 1
      };

    return (
        <div id="skills" className="mt-4 text-white">
            <h1 className="text-2xl font-bold">Skills</h1>
            <p className="font-light text-gray-400">Here are some of my skills</p>

            <div className="mt-4">
                <Slider {...settings}>
                <SkillCard name="gcp" experience="1 years" img={gcp} />
                <SkillCard name="azure" experience="1 years" img={azure} />
                <SkillCard name="jenkins" experience="1 years" img={jenkins} />
                <SkillCard name="docker" experience="1 years" img={docker} />
                <SkillCard name="kubernetes" experience="1 years" img={kubernetes} />
                <SkillCard name="ansible" experience="1 years" img={ansible} />
                <SkillCard name="terraform" experience="1 years" img={terraform} />
                <SkillCard name="vault" experience="1 years" img={vault} />
                <SkillCard name="helm" experience="1 years" img={helm} />
                <SkillCard name="git" experience="1 years" img={git} />
                <SkillCard name="gitlab" experience="1 years" img={gitlab} />
                <SkillCard name="newrelic" experience="1 years" img={newrelic} />
                <SkillCard name="prometheus" experience="1 years" img={prometheus} />
                <SkillCard name="datadog" experience="1 years" img={datadog} />
                <SkillCard name="grafana" experience="1 years" img={grafana} />
                <SkillCard name="linux" experience="1 years" img={linux} />
                <SkillCard name="bash" experience="1 years" img={bash} />
                <SkillCard name="python" experience="1 years" img={python} />
                <SkillCard name="nodejs" experience="1 years" img={nodejs} />
                </Slider>
            </div>
            <img src={hr} className="w-full mt-8 md:h-3" alt="hr" />
        </div>
    )
}