import './App.css';
import {useEffect} from 'react';
import ReactGA from "react-ga4";

import Navbar from './components/Navbar';
import Hiro from './components/Hiro';
import Skills from './components/Skills'
// import Honors from './components/Honors';
import Certs from './components/Certs';
import Footer from './components/Footer';
import AOS from 'aos';
import 'aos/dist/aos.css';

import { TRACKING_ID } from "./data/tracking";

function App() {
    useEffect(() => {
      document.title = 'Syam';
      AOS.init();
      if (TRACKING_ID !== "") {
        ReactGA.initialize(TRACKING_ID);
      }
    }, []);
  return (
    <div className="px-6 lg:px-20 xl:px-36 bg-dark-500">
      <Navbar />
      <Hiro />
      <Skills />
      {/* <Honors /> */}
      <Certs />
      <Footer />
    </div>
  );
}

export default App;
